import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Img from "gatsby-image";
import Footnote from "./Footnote";

import layoutBackgroundImage from "../images/background-flourish.svg";
import iconClose from "../images/icons/close.svg";
import iconPrevious from "../images/icons/previous.svg";
import iconNext from "../images/icons/next.svg";

const PhotoGallery = ({ items }) => {
  interface ModalState {
    isVisible: boolean;
    activeItem: number;
  }

  const [modalState, setModalState] = useState<ModalState>({
    isVisible: false,
    activeItem: 0,
  });

  const handleClick = (e, index) => {
    e.preventDefault();
    setModalState({
      isVisible: !modalState.isVisible,
      activeItem: index,
    });
  };

  const goBack = () => {
    setModalState({
      ...modalState,
      activeItem: modalState.activeItem - 1,
    });
  };

  const goForward = () => {
    setModalState({
      ...modalState,
      activeItem: modalState.activeItem + 1,
    });
  };

  const closeModal = () => {
    setModalState({
      ...modalState,
      isVisible: false,
    });
  };

  const itemTitle = (originalName: string) => {
    return originalName
      .replace(/\d+\-/g, "") // Removes the item number
      .replace(/-/g, " ") // Replaces dashes with spaces
      .replace(".jpg", ""); // Removes .jpg extension
  };

  const handleKeypress = event => {
    event.preventDefault();

    if (modalState.isVisible) {
      const { activeItem } = modalState;
      const { key } = event;
      if (key === "ArrowLeft" && activeItem > 0) {
        goBack();
      }

      if (key === "ArrowRight" && activeItem < items.length - 1) {
        goForward();
      }

      if (key === "Escape") {
        closeModal();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeypress, false);
    return () => {
      window.removeEventListener("keyup", handleKeypress, false);
    };
  }, [modalState]);

  return (
    <Frame>
      <Gallery>
        {items.map((item, i) => (
          <GalleryItem key={item.node.childImageSharp.fluid.src} onClick={e => handleClick(e, i)}>
            <Title>{itemTitle(item.node.childImageSharp.fluid.originalName)}</Title>
            <ItemImg fluid={item.node.childImageSharp.fluid} />
          </GalleryItem>
        ))}
      </Gallery>

      <GalleryModal visible={modalState.isVisible} onKeyPress={handleKeypress}>
        <ModalNav>
          <Button onClick={goBack} disabled={modalState.activeItem === 0}>
            <img src={iconPrevious} alt="Previous" />
          </Button>
          <Button onClick={goForward} disabled={modalState.activeItem === items.length - 1}>
            <img src={iconNext} alt="Next" />
          </Button>
          <Button onClick={() => closeModal()}>
            <img src={iconClose} alt="Close" />
          </Button>
        </ModalNav>
        <ModalImg fluid={items[modalState.activeItem].node.childImageSharp.fluid} />
        <ModalFootnote>
          {itemTitle(items[modalState.activeItem].node.childImageSharp.fluid.originalName)}
        </ModalFootnote>
      </GalleryModal>
    </Frame>
  );
};

const Frame = styled.div`
  --gallery-spacing: 1rem;
  margin-top: 2rem;
`;

const Gallery = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 288px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 196px;
  }

  @media (max-width: 512px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 196px;
  }
`;

const GalleryItem = styled.a`
  display: flex;
  background-color: #222;
  position: relative;
  cursor: zoom-in;
  transition: transform 250ms;

  &:hover {
    transform: scale(1.025);

    &:after,
    > h4 {
      opacity: 0;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: var(--gallery-spacing);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: opacity 250ms;
  }

  @media (min-width: 960px) {
    &:nth-of-type(8n + 1) {
      grid-column: auto / span 2;
      grid-row: auto / span 1;
    }

    &:nth-of-type(8n + 2) {
      grid-column: auto / span 1;
      grid-row: auto / span 2;
    }

    &:nth-of-type(8n + 3) {
      grid-column: auto / span 1;
      grid-row: auto / span 1;
    }

    &:nth-of-type(8n + 4) {
      grid-column: auto / span 1;
      grid-row: auto / span 1;
    }

    &:nth-of-type(8n + 5) {
      grid-column: auto / span 1;
      grid-row: auto / span 2;
    }

    &:nth-of-type(8n + 6) {
      grid-column: auto / span 2;
      grid-row: auto / span 1;
    }

    &:nth-of-type(8n + 7) {
      grid-column: auto / span 1;
      grid-row: auto / span 1;
    }

    &:nth-of-type(8n + 8) {
      grid-column: auto / span 1;
      grid-row: auto / span 1;
    }
  }
`;

const Title = styled.h4`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  z-index: 2;
  padding: calc(var(--gallery-spacing) * 2);
  letter-spacing: 0em;
  line-height: 1em;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  transition: opacity 250ms;
`;

const ItemImg = styled(Img)`
  width: 100%;
`;

const GalleryModal = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${props => (props.visible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 3rem;
  box-sizing: border-box;
  background-color: var(--cream-bg);
  background-image: url(${layoutBackgroundImage});
  background-size: cover;
  background-position: center;
  z-index: 2;
`;

const ModalImg = styled(Img)`
  width: 100%;
  max-width: 90vw;
  max-height: 90vh;
`;

const ModalNav = styled.nav`
  display: grid;
  grid-template-columns: repeat(2, max-content) auto;
  grid-gap: var(--gallery-spacing);
  margin-bottom: var(--gallery-spacing);
  width: 100%;
`;

const Button = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0.5rem;
  outline: none;
  border: 1px solid transparent;

  &:focus {
    border-color: rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:disabled {
    opacity: 0.25;
    cursor: inherit;
  }

  &:last-child {
    margin-left: auto;
  }
`;

const ModalFootnote = styled(Footnote)`
  margin-top: var(--gallery-spacing);
  letter-spacing: 0;
  font-size: 1rem;
`;

export default PhotoGallery;
