import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Img from "../components/Img";
import PhotoSection from "../components/PhotoSection";
import FloatingArticle from "../components/FloatingArticle";
import Article from "../components/Article";
import Nav from "../components/Nav";
import Footnote from "../components/Footnote";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Paragraph from "../components/Paragraph";
import PhotoGallery from "../components/PhotoGallery";
import ParagraphPull from "../components/ParagraphPull";

const EstatePage = ({ data }) => {
  const [currentSeason, setCurrentSeason] = useState("summer");
  const [seasonGallery, setSeasonGallery] = useState(data.summerGallery.edges);

  useEffect(() => {
    switch (currentSeason) {
      case "summer":
        setSeasonGallery(data.summerGallery.edges);
        break;
      case "winter":
        setSeasonGallery(data.winterGallery.edges);
        break;
      default:
        setSeasonGallery(data.summerGallery.edges);
    }
  }, [currentSeason]);

  return (
    <Layout>
      <SEO title="Grounds" />
      <Nav />
      <Section>
        <Article isCentered>
          <H1>Grounds</H1>
          <Paragraph isLarge>
            The grounds of Oak Hall were designed in 1912 by Warren H. Manning, proponent of the
            naturalistic wild garden approach to landscape architecture. His work lives on over 100
            years later as a testament to his vision and the natural beauty of coastal Maine.
          </Paragraph>
        </Article>
      </Section>
      <Section>
        <Img
          imgSrc={data.groundsLayout.childImageSharp.fluid}
          imgAlt="Oak Hall grounds layout"
          isTransparent
        />
      </Section>
      <Section>
        <Article>
          <Paragraph>
            Apart from the home itself, the grounds of Oak Hall set it apart as a world class
            estate. Before Marshall &amp; Fox began designing the lavish Colonial Revival mansion,
            Warren H. Manning, a Boston landscape architect, began to lay out the roads, trails,
            lawns, and gardens to emphasize the character of the site’s natural environment. Manning
            took advantage of the boulders on the glacial hill to build stone walls along the drive
            and a retaining wall for the formal garden. Some giant boulders were left in place as
            sentinels from the past.
          </Paragraph>
          <Paragraph>
            He wrapped the hill in a winding drive that gives a commanding view of Penobscot Bay and
            exaggerates the height of the mansion’s columns as one approaches from below. Using the
            rural meadow to create expansive lawns, Manning emphasized the far-off views of
            mountains and ocean. He used massed plantings of native trees and shrubs – spruce, firs,
            arbor vitae, rhododendron, viburnum, spiraea, and honeysuckle – to provide definition
            and privacy. And the formal gardens close to the house provided beautiful detail and
            color.
          </Paragraph>
        </Article>
      </Section>

      <Section>
        <Article>
          <H2>Warren H. Manning</H2>
          <ParagraphPull orientation="LEFT">
            <Img
              imgSrc={data.warrenManningBook.childImageSharp.fluid}
              imgAlt="Book cover: Warren H. Manning: Landscape Architect and Environmental Planner"
            />
            <Footnote>
              Manning’s work at Oak Hall is documented in ‘Warren H. Manning: Landscape Architect
              and Environmental Planner’ by Robin Karson, and published by Library of American
              Landscape History.
            </Footnote>
          </ParagraphPull>
          <Paragraph isLarge>
            Warren H. Manning and his firm were responsible for the conversion of what was Hillside
            Farm in Northport, into the picturesque grounds of today’s Oak Hall.
          </Paragraph>

          <Paragraph>
            Manning’s work on the grounds began even before construction of the residence and lasted
            through the late 1910s.
          </Paragraph>
          <Paragraph>
            Born in 1860 in Massachusetts, Manning would spend over a decade working for notable
            American landscape designer Frederick Law Olmsted. He went on to establish his own firm,
            where he developed a distinct style — largely inspired by 18th-century English
            romanticism, but adapted to the American landscape, resulting in sprawling, natural
            designs, littered with native plants.
          </Paragraph>
          <Paragraph>
            Manning would go on to found the American Society of Landscape Architects and was an
            early proponent of the National Park System.
          </Paragraph>
        </Article>
      </Section>
      <PhotoSection background={data.backgroundForest.childImageSharp.fluid.src}>
        <FloatingArticle>
          <H2>Wild Gardens</H2>
          <Paragraph>
            Manning’s penchant for the picturesque can be seen all around the estate. The built
            environment merges on the periphery with the New-England Acadian forest biome which Oak
            Hall calls home.
          </Paragraph>
          <Paragraph>
            The woodland is home to countless species of oak, syrup producing maple, wild
            blueberries, and a wide variety of wild fern. Over ten miles of original, rustic walking
            trails are slowly being reclaimed by the present owners.
          </Paragraph>
          <Footnote>
            The Estate includes the original water tower — now a local navigational landmark — once
            used to ensure proper water pressure for Oak Hall’s extensive plumbing system.
          </Footnote>
        </FloatingArticle>
      </PhotoSection>
      <Section>
        <Article>
          <H2>The Sunken Garden</H2>
          <Paragraph isLarge>
            Of the major foundational elements of Manning’s original design, only the formal sunken
            garden and reflecting pool are no longer extant.
          </Paragraph>
        </Article>
        <Img
          imgSrc={data.gardensPostcard.childImageSharp.fluid}
          imgAlt="Postcard: The Gardens, Hillside Farms, Estate of Ira M. Cobe, Northport, ME (1937)"
          isWide
        />
        <Article>
          <Paragraph>
            They were replaced with an outdoor pool by the Pingree family in the 1950s. Today,
            echoes of the formal garden remain in the cedar hedges that stand thirty feet tall and
            surround a small Knot garden that sits in its place.
          </Paragraph>
        </Article>
      </Section>
      <Section>
        <ToggleGallery>
          <ToggleHeader>
            <H2>Seasons</H2>
          </ToggleHeader>
          <ToggleSwitch>
            <Toggle
              onClick={() => setCurrentSeason("summer")}
              isActive={currentSeason === "summer" && true}
            >
              Summer
            </Toggle>
            <Toggle
              onClick={() => setCurrentSeason("winter")}
              isActive={currentSeason === "winter" && true}
            >
              Winter
            </Toggle>
          </ToggleSwitch>
        </ToggleGallery>
        <PhotoGallery items={seasonGallery} />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    summerGallery: allFile(filter: { absolutePath: { regex: "/grounds/gallery/summer/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2048) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    winterGallery: allFile(filter: { absolutePath: { regex: "/grounds/gallery/winter/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2048) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    groundsLayout: file(absolutePath: {regex: "/\\/grounds\\/grounds-layout\\.png/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    warrenManningBook: file(absolutePath: {regex: "/\\/grounds\\/warren-manning-book\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    backgroundForest: file(absolutePath: {regex: "/\\/grounds\\/background-forest\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gardensPostcard: file(absolutePath: {regex: "/\\/grounds\\/gardens-postcard\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ToggleGallery = styled.div`
  --header-spacing: 16px;
  display: grid;
  grid-template-columns: auto max-content;
  margin-bottom: calc(var(--header-spacing) * 2);

  @media (max-width: 512px) {
    grid-template-columns: inherit;
  }
`;

const ToggleHeader = styled.header`
  display: grid;
  grid-gap: var(--header-spacing);
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 1.15em;
  margin-bottom: var(--header-spacing);
`;

const ToggleSwitch = styled.nav`
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  width: max-content;
  margin: 0 auto;
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: var(--header-spacing);

  @media (max-width: 512px) {
    width: 100%;
  }
`;

const Toggle = styled.button<{ isActive: boolean }>`
  font-family: "Calibre", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.8rem;
  letter-spacing: 0.5em;
  border: none;
  border-radius: 0.35rem;
  padding: 0.75rem 1.75rem;
  cursor: pointer;
  background-color: ${props => (props.isActive ? "#FFF" : "transparent")};
  box-shadow: ${props => (props.isActive ? "0px 10px 12px 0px rgba(0, 0, 0, 0.05)" : "none")};
  opacity: ${props => (props.isActive ? "1" : "0.25")};

  &:hover {
    opacity: 1;
  }
`;

export default EstatePage;
