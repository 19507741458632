import React from "react";
import styled from "styled-components";

import Section from "../components/Section";

const PhotoSection = ({ children, background, backgroundPosition = "center" }) => {
  return (
    <Frame background={background} backgroundPosition={backgroundPosition}>
      {children}
    </Frame>
  );
};

const Frame = styled(Section)<{ background: string; backgroundPosition?: string }>`
  --card-spacing: 96px;

  background-image: url(${props => props.background});
  background-size: cover;
  background-position: ${props => props.backgroundPosition};
  margin-top: calc(var(--card-spacing) * 2);
  margin-bottom: calc(var(--card-spacing) * 2);
  clear: both;

  @media (max-width: 960px) {
    --card-spacing: 64px;
  }
`;

export default PhotoSection;
