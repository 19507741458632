import React from "react";
import styled from "styled-components";

import Article from "./Article";

const FloatingArticle = ({ children }) => {
  return <Frame>{children}</Frame>;
};

const Frame = styled.article`
  display: grid;
  grid-gap: 16px;
  position: relative;
  max-width: 45%;
  height: 100%;
  margin-left: auto;
  box-sizing: border-box;
  padding: 0 var(--card-spacing);

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: calc(var(--card-spacing) * -1);
    bottom: calc(var(--card-spacing) * -1);
    background-color: #fff;
    width: 100%;
    z-index: 0;
    box-shadow: 0px 64px 128px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 960px) {
    max-width: inherit;
    padding: 0 calc(var(--card-spacing) / 2);
  }
`;

export default FloatingArticle;
